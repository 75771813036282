import axios from "axios";
import { createContext, useContext, useState, useEffect } from "react";
import { getCurrentSubscriptionPlaned } from "../helper/getCurrentSubscriptionPlaned";

const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [userAuth, setUserAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userCurrentSubscription, setUserCurrentSubscription] = useState([]);
  const [userSubscription, setUserSubscription] = useState([]);
  const [socketClientConnection, setSocketClientConnection] = useState(null);

  const shareValue = {
    userAuth,
    setUserAuth,

    userData,
    setUserData,

    userCurrentSubscription,
    setUserCurrentSubscription,

    userSubscription,
    setUserSubscription,

    isLoading,
    socketClientConnection, 
    setSocketClientConnection
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user_public");
    if (token && user) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/account/profile/${user}`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            setUserAuth(true);
            setUserData(res.data.user);
            setUserSubscription(res.data.user.subscriptions);
            setUserCurrentSubscription(
              getCurrentSubscriptionPlaned(res.data.user.subscriptions)
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <UserContext.Provider value={shareValue}>{children}</UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
