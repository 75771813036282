import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const fetchAppointments  = createAsyncThunk(
    'appointments/fetchAppointments',
    async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/athenahealth/appointments`, {
            
            })
        return response.json()
    }
)

const appointmentsSlice = createSlice({
    name:"appointments",
    initialState:{
        isLoading : false,
        data : [],
        isError: null
    },
    extraReducers:  (builder) => {
        builder.addCase(fetchAppointments.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(fetchAppointments.fulfilled, (state, action) => {
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchAppointments.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            console.log("Error: ", action.payload);
        })
        
    }
})

export default appointmentsSlice.reducer;

