import {configureStore} from '@reduxjs/toolkit'
import appointmentReducer from './slices/athena/appointments'

// import {reducer} from './reducer'

export const store = configureStore({
    reducer:{
        appointments:appointmentReducer,
    }
})
