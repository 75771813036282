import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import "../src/assets/css/style.css";
import "./portal/assets/css/portal.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import { useUserContext } from "./context/UserContext";
import PrivateRoute from "./routes/PrivateRoute";

// Pages
const Home = React.lazy(() => import("./pages/Home"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const Contact = React.lazy(() => import("./pages/Contact"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsAndConditions = React.lazy(() =>
  import("./pages/TermsAndConditions")
);
const RequestADemo = React.lazy(() => import("./pages/RequestADemo"));
const SignUp = React.lazy(() => import("./pages/SignUp"));
const PaymentSuccess = React.lazy(() => import("./pages/PaymentSuccess"));
const Loading = React.lazy(() => import("./portal/components/Loading"));
const Pricing = React.lazy(() => import("./pages/Pricing"));

//dashboard
// const Dashboard = React.lazy(() => import("./portal/pages/Home"));
const HomePage = React.lazy(() => import("./portal/pages/HomePage"));
const DiagnoseDictationRecording = React.lazy(() =>
  import("./portal/pages/DiagnoseDictationRecording")
);
const DiagnoseDictationText = React.lazy(() =>
  import("./portal/pages/DiagnoseDictationText")
);
const DiagnoseDictationUpload = React.lazy(() =>
  import("./portal/pages/DiagnoseDictationUpload")
);
const PasswordChangePage = React.lazy(() =>
  import("./portal/pages/PasswordChangePage")
);
const Login = React.lazy(() => import("./portal/pages/Login"));
const ChangePassword = React.lazy(() =>
  import("./portal/pages/ChangePassword")
);
const VerifyAccount = React.lazy(() => import("./pages/VerifyAccount"));
const History = React.lazy(() => import("./portal/pages/History"));
const HomeRecording = React.lazy(() => import("./portal/pages/HomeRecording"));
const Sandbox = React.lazy(() => import("./pages/Sandbox"));
const Sandbox2 = React.lazy(() => import("./pages/Sandbox2"));
const Sandbox3 = React.lazy(() => import("./pages/Sandbox3"));
const Sandbox4 = React.lazy(() => import("./pages/Sandbox4"));
const CurrentSubscription = React.lazy(() =>
  import("./portal/pages/CurrentSubscription")
);
const Error = React.lazy(() => import("./portal/pages/Error"));

const App = () => {
  const { userAuth, userData, isLoading } = useUserContext();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Toaster position="top-right" reverseOrder={false} />
        <Routes>
          <Route
            path="/"
            element={<Home userAuth={userAuth} userData={userData} />}
          />        
          <Route
            path="/about-us"
            element={<AboutUs userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/faqs"
            element={<FAQs userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/contact-us"
            element={<Contact userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/privacy-policy"
            element={<PrivacyPolicy userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/terms-and-conditions"
            element={
              <TermsAndConditions userAuth={userAuth} userData={userData} />
            }
          />
          <Route
            path="/request-a-demo"
            element={<RequestADemo userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/payment-success"
            element={<PaymentSuccess userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/pricing"
            element={<Pricing userAuth={userAuth} userData={userData} />}
          />

          {/* dashboard */}
          <Route
            path="/account/"
            element={<Login userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/sandbox"
            element={<Sandbox userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/sandbox2"
            element={<Sandbox2 userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/sandbox3"
            element={<Sandbox3 userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/sandbox4"
            element={<Sandbox4 userAuth={userAuth} userData={userData} />}
          />
          {/* <Route
            path="/account/home"
            element={<Dashboard userAuth={userAuth} userData={userData} />}
          /> */}
          {/* <Route
            path="/account/history"
            element={<History userAuth={userAuth} userData={userData} />}
          /> */}

          <Route
            path="/account/home"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <HomePage userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/diagnose-dictation-recording"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <DiagnoseDictationRecording userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/diagnose-dictation-text"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <DiagnoseDictationText userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/diagnose-dictation-audio"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <DiagnoseDictationUpload
                  userAuth={userAuth}
                  userData={userData}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="/account/home-rec"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <HomeRecording userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/history"
            element={
              <PrivateRoute isSubscriptionRequired={true}>
                <History userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />

          <Route
            path="/account/current-subscription"
            element={
              <PrivateRoute>
                <CurrentSubscription userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/account/change-password"
            element={
              <PrivateRoute>
                <PasswordChangePage userAuth={userAuth} userData={userData} />
              </PrivateRoute>
            }
          />
          <Route path="/account/login" element={<Navigate to="/" />} />
          <Route
            path="/account/sign-up"
            element={<SignUp userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/account/set-password/:user/:token"
            element={<ChangePassword userAuth={userAuth} userData={userData} />}
          />
          <Route
            path="/account/verify-account/:user/:token"
            element={<VerifyAccount userAuth={userAuth} userData={userData} />}
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
